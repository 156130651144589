<template>
  <base-section
    id="info-alt"
    space="56"
  >
    <v-container>
      <v-row justify="space-between">
        <v-col
          cols="12"
          md="5"
        >
          <base-business-info />
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <!-- contact form -->
          <div>
            <base-info-card
              :title="$t('KeepInTouch.title')"
              space="4"
              color="primary"
            />

            <base-text-field
              v-model="userEmail"
              :label="$t('KeepInTouch.textFieldEmail')"
            />

            <base-text-field
              v-model="subject"
              :label="$t('KeepInTouch.textFieldSubject')"
            />

            <base-textarea
              v-model="body"
              class="mb-6"
              :label="$t('KeepInTouch.textArea')"
            />

            <base-btn
              :color="!theme.isDark ? 'accent' : 'white'"
              :href="`mailto:${emailFormat()}`"
              outlined
              target="_blank"
            >
              {{ $t('KeepInTouch.btnText') }}
            </base-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  import constants from '@/config.js'
  export default {
    name: 'SectionKeepInTouch',
    // Injected from the Vuetify Themeable mixin
    inject: ['theme'],
    email: null,
    data: () => ({
      email: constants.MAIL,
      userEmail: null,
      subject: null,
      body: null,
    }),
    methods: {
      emailFormat () {
        let emailString = this.email
        if (this.subject) {
          emailString += `?subject=${this.subject}`
        }
        if (this.body) {
          emailString += `&body=${this.body}`
        }
        return emailString
      },
    },
  }
</script>
